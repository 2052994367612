import getters from './getters';

describe('getters', () => {
  describe('isDirty', () => {
    it('should return true if one of dirty is true', () => {
      const state = {
        dirty: {
          test: true
        }
      };

      expect(getters.isDirty(state)).toBe(true);
    });
  });
  describe('isBusy', () => {
    it('should return true if being dirty', () => {
      const theGetters = {
        isDirty: true
      };
      const theRootGetters = {
        'search/hasSearchedResult': true
      };

      expect(getters.isBusy({}, theGetters, {}, theRootGetters)).toBe(true);
    });
  });
});
